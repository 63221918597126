@mixin logo($height, $text-ratio: 0.6) {
  & .logo {
    height: $height;
    &:hover {
      transform: rotate(360deg);
      transition: transform 0.8s ease-in-out;
    }
  }
  & .logo-text {
    height: $height * $text-ratio;
    margin: ($height * (1-$text-ratio))/2 0;
    margin-left: $height * 0.1;
  }
}
@mixin section-bg($url, $top: 0.85, $bottom: 0.85) {
  background: linear-gradient(rgba(102,51,153, $top), rgba(102,51,153, $bottom)),
    url($url) center center;
    background-size: cover;
}

$logo-height: 100px;

.section {
  padding: ($font-size-base * 2) 0;
  padding: 40px 0;
}

.lined {
  border-bottom: 5px solid #663399;
}

.avatar {
  border-radius: 50%;
  shape-outside: circle(50%);
}

.media-left {
  padding-right: 30px;
}


.footer {
  background: #242424;
}


.homepage {
  background: #ffffff;
  .imgholder {
    height: 150px;
    margin: auto;
    display: inline-flex;
    align-items: center;
    img {
      vertical-align: middle;
    }
  }
  .avatar {
    border: 5px solid #663399;
    max-width: 100%;
  }
  .benefits {
    @include section-bg('../pycon2017-davidism.jpg', 0.8, 1);
  }
  .objections {
    @include section-bg('../pycon2017-davidism2-cropped.jpg', 0.8, 1);
  }
  .bottom {
    @include section-bg('../djangocon-2016-cropped.jpg', 0.85, 0.85);
  }
  .course-block {
    width: 480px;
    height: 270px;
    background: #663399;
    margin: 0 auto 30px;
    display: inline-flex;
    align-items: center;
    text-decoration: none;
    h4 {
      color: #fff;
      margin: auto;
    }
  }
  .basics .course-block {
    @include section-bg('../remote.jpg');
  }
  .oneday .course-block {
    @include section-bg('../gdi2.jpg');
  }
  .pythonjs .course-block {
    @include section-bg('../gdi1.jpg');
  }
  .django-intro .course-block {
    @include section-bg('../pycon-au-comprehensions.png');
  }
  .benefits, .objections, .bottom {
    p, h4, h3 {
      color: #fff;
    }
  }
  .btn-lg {
    padding: 12px 50px;
  }
  .btn-md {
    padding: 10px 30px;
  }
  .footer {
    background: none;
    .footerimg {
      margin: -20px;
    }
    a {
     i {
       margin-right: 5px;
     }
    }
    .copyright {
       font-size: 14px;
       margin: 20px 0 0;
    }
  }
}
.small-top-buffer { margin-top:20px; }

.row.top-buffer {
  > * {
    margin-top:40px;
  }
}

@media (max-width: $screen-sm-max) {
  .homepage .course-block{
    max-width: 100%;
  }
}

@media (max-width: $screen-xs-max) {
  .homepage iframe {
    height: 280px !important;
  }
}

// Landing pages
@media (min-width: $screen-sm-min) {
  body.narrow .container {
    max-width: 730px;
  }
  body.narrow .section {
    padding: $font-size-base 0;
  }
}
.section-header {
  font-weight: bold;
}
.small-logo {
  height: 4em;
  width: 4em;
  margin: 1em;
}


// Header container hack
@media (max-width: $screen-sm-max) {
   .container.container-nav {
      width: 100%;
   }
}


// Hacks to fix line height of navbar links
.navbar-nav > li {
  font-size: $font-size-large;
}
header {
  @include logo($logo-height*0.8);
}
@media (min-width: $screen-sm-min) {
  header {
    @include logo($logo-height*0.8);
  }
  .navbar-nav > li > a {
     line-height: 80px;
  }
}
@media (min-width: $screen-md-min) {
  header {
    @include logo($logo-height*0.9);
  }
  .navbar-nav > li > a {
     line-height: 90px;
  }
}

@media (min-width: $screen-lg-min) {
  header {
    @include logo($logo-height);
  }
  .navbar-nav > li > a {
     line-height: 100px;
  }
}

// Hacks to fix logo
.navbar-brand {
  > img {
   display: inline-block;
 }
}

.video-container {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px; height: 0; overflow: hidden;
}

.video-container iframe,
.video-container object,
.video-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
