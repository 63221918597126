$spacer: 1rem !default;
$spacers: (
  0: 0,
  1: ($spacer * .25),
  2: ($spacer * .5),
  3: $spacer,
  4: ($spacer * 1.5),
  5: ($spacer * 3)
) !default;

@each $size, $length in $spacers {
  .mt-#{$size} {
    margin-top: $length !important;
  }
}

